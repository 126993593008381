/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Env from "app/core/environment";
import Resource from "app/core/resource";

class Consultation extends React.Component {
    constructor(props) {
        super(props);

        this.Resource = Resource;
        this.HTMLResource = Resource.getHTML(Env.getInstance().getLanguage());
        this.stringsResource = Resource.getStrings(Env.getInstance().getLanguage());
    }

    render() {
        return (
            <div
                className={classnames(
                    "consultation d-md-flex justify-content-between align-items-center rounded-16 color-white p-16",
                    this.props.className
                )}
            >
                <div className="d-md-flex align-items-center">
                    <div className="consultation__header">
                        <div className="d-flex align-items-center mb-16">
                            <img
                                className="consultation__pharmacist-photo lazyload"
                                src={this.Resource.links.images.pharmacistPreview}
                                alt="pharmacist"
                                width={60}
                                height={60}
                            />

                            <div>
                                <div className="consultation__title">
                                    {this.HTMLResource.pharmacistConsultation.title}
                                </div>

                                <div
                                    dangerouslySetInnerHTML={{__html: this.HTMLResource.pharmacistConsultation.description}}
                                    className="consultation__description d-none d-md-block"
                                />
                            </div>
                        </div>

                        <div
                            dangerouslySetInnerHTML={{__html: this.HTMLResource.pharmacistConsultation.description}}
                            className="consultation__description d-md-none"
                        />
                    </div>
                </div>

                <div className="consultation__body">
                    <div className="consultation__social social d-flex">
                        <a
                            className="social__link social__link--telegram"
                            href={this.Resource.links.telegramPharmacistConsultation}
                            target="_blank"
                        >
                            <img
                                className="social__preview lazyload"
                                src={this.Resource.links.images.telegram.previewRounded}
                                alt="Telegram"
                                width={38}
                                height={38}
                            />

                            <span className="social__name">
                                Telegram
                            </span>
                        </a>

                        <a
                            className="social__link social__link--viber"
                            href={this.Resource.links.viberPharmacistConsultation}
                            target="_blank"
                        >
                            <img
                                className="social__preview lazyload"
                                src={this.Resource.links.images.viber.previewRounded}
                                alt="Viber"
                                width={38}
                                height={38}
                            />

                            <span className="social__name">
                                Viber
                            </span>
                        </a>
                    </div>

                    <div
                        dangerouslySetInnerHTML={{__html: this.HTMLResource.pharmacistConsultation.workingTime}}
                        className="consultation__working-time"
                    />
                </div>
            </div>
        );
    }
}

Consultation.propTypes = {
    className: PropTypes.string
};

Consultation.defaultProps = {
    className: ""
};

export default Consultation;
